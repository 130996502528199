import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { hostus } from "@/utils/func.js";

//const hostus = (process.env.NODE_ENV == "development" ? 'http://localhost:3000' : '')

const state = {
    token: getToken() || '',
    debug: false,
    isAuth: false,
    isAdmin: false,
    isAdm: false,
    user_da: {
        name: '',
        email: '',
        id: '',
    },
    kl_elector: 0,
    elect_id: null,
    curElect: null,
    curResult: null,
    actual: true,
  }
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER_DA: (state, user_da) => {
        state.user_da = Object.assign(state.user_da, user_da) // замена/добавление свойств
        //state.user_da = {...user_da} // просто копия
    },
    SET_DEBUG: (state, debug) => {
        state.debug = debug
    },
    SET_ISAUTH: (state, isauth) => {
        state.isAuth = isauth
    },
    SET_ISADMIN: (state, isadmin) => {
        state.isAdmin = isadmin
    },
    SET_ISADM: (state, isadm) => {
        state.isAdm = isadm
    },
    //set_elect_id: (state, el_id) => { state.elect_id = el_id },
    set_kl_elector: (state, kl_elector) => {
        state.kl_elector = kl_elector   
    },
    set_curElect: (state, curElect) => {
        state.curElect = Object.assign({}, curElect)   
    },
    set_curResult: (state, curResult) => {
        state.curResult = curResult.slice()  
    },
    set_actual: (state, actual) => {
        state.actual = actual  
    },
}

const actions = {
    login({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            let obj = { username: username.trim(), password: password }
            //console.log('obj=',obj)
            axios.post(`${hostus}/mysqlus/select`, obj).then(response => {
                const { data } = response
                let msg = ''
                if (data.error) { msg = data.error.code }
                else {
                    if (data.auth) { //debugger
                        const { id, username, grup } = data.user
                        let token = { username: username, id: id, grup: grup }
                        commit('SET_TOKEN', token)
                        commit('SET_USER_DA', data.user)
                        commit('SET_ISAUTH', true)
                        commit('SET_ISADMIN', /^admin/.test( grup ))
                        commit('SET_ISADM', /^adm/.test( grup ))
                        setToken(token)
                        //console.log('state.user_da=',state.user_da,data.user)
                    }
                    else { // аут не прошла
                        if (data.errmsg == "401") msg = "Ошибка. Неверный пароль"
                        if (data.errmsg == "404") msg = "Ошибка. Такой логин не зарегистрирован"
                    }
                }
                resolve(msg)
            })
            .catch(error => { reject(error) })
        })
    },

    register({ commit }, userInfo) {debugger
        //const { username, password, grup } = userInfo
        return new Promise((resolve, reject) => {
            //let obj = { username: username, password: password, grup: grup }
            axios.post(`${hostus}/mysqlus/insert`, userInfo).then(response => {
                const { data } = response
                let msg = ''
                if (data.error) {
                    msg = data.error.code
                    if (data.error.code == "ER_DUP_ENTRY") msg = "Ошибка. Пользователь с таким логином уже зарегистрирован."
                    else msg = "Ошибка " + data.error.message
                }
                else { commit('SET_ISAUTH', true)
                    msg = data.okmsg
                // commit('SET_TOKEN', data.okmsg)
                // setToken(data.okmsg)
                }
                resolve(msg)
            }).catch(error => {console.log('error=',error); reject(error) })
        })
    },

    update({ commit }, updInfo) {
        //console.log('updInfo=',updInfo)
        return new Promise((resolve, reject) => {
          updInfo.uId = state.user_da.id
          axios.put(`${hostus}/mysqlus/update`, updInfo)
            .then(response => {
                const { data } = response
                let msg = ''
                if (data.errmsg) { msg = data.errmsg }
                else {
                    commit('SET_USER_DA', updInfo)
                }
                resolve(msg)
            })
            .catch(error => { reject(error) })
        })
    },
        
    logout({ commit }) {
        return new Promise( resolve => {
            commit('SET_TOKEN', '')
            commit('SET_USER_DA', {})
            commit('SET_ISAUTH', false)
            commit('SET_ISADMIN', false)
            removeToken()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}