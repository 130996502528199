<template>
  <div class="nav" oncontextmenu="return false">
    <el-row>
      <router-link v-if="!isAuth()" to="/" exact><el-button round plain type="primary" size="small"><span class="buttxt">Главная</span></el-button></router-link>
      <router-link v-if="!isAuth()" to="/login"><el-button round plain type="success" size="small"><span class="buttxt">Вход ЛК</span></el-button></router-link>
      <router-link v-if="!isAuth()" to="/about"><el-button round plain type="info" size="small"><span class="buttxt">О проекте</span></el-button></router-link>
      <el-dropdown v-if="isAuth()">
        <el-button round plain type="primary" size="small"><span class="buttxt">
          Меню <el-icon><arrow-down-bold /></el-icon>
        </span></el-button>
        <template #dropdown>
          <el-dropdown-menu style="width:210px; background-color:#d9f3f3">
            <router-link v-if="isAuth()" to="/cabinfo" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Информация</span></el-dropdown-item></router-link>
            <router-link v-if="isAuth()" to="/profile" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Профиль</span></el-dropdown-item></router-link>
            <router-link v-if="isAuth()" to="/elect" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Голосование</span></el-dropdown-item></router-link>
            <router-link v-if="isAdm()" :to="isResult?'/elect/result':'/elect'" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Результаты</span></el-dropdown-item></router-link>
            <router-link v-if="isAdmin()" to="/manage" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Управление</span></el-dropdown-item></router-link>
            <router-link v-if="isAdmin()" to="/register" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Регистрация</span></el-dropdown-item></router-link>
            <router-link v-if="isAdm()" to="/electorate" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Участники</span></el-dropdown-item></router-link>
            <router-link v-if="isAuth()" to="/pass" style="text-decoration: none;">
              <el-dropdown-item><span style="font-size: 1.6em;">Смена пароля</span></el-dropdown-item></router-link>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-button v-if="isAuth()" round plain type="warning" size="small" @click="out(true)"><span class="buttxt">Выход</span></el-button>
    </el-row><br>
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAuth: () => this.$store.getters['isAuth'],
      isAdmin: () => this.$store.getters['isAdmin'],
      isAdm: () => this.$store.getters['isAdm']
      //isAdmin: () => /^admin/.test( (this.$store.getters['user_da']).grup )
    }
  },

  created() { 
    window.addEventListener('beforeunload', this.beforeWindowUnload) // добавление события "до перезагузки"
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)  // удаление события
  },

  mounted: function () {
    const url = new URL(window.location.href)
    console.log(window.location.search, url.searchParams.get('debug'))
    if (url.searchParams.get('debug') == 1) this.$store.commit('user/SET_DEBUG', true) //process.env['DEBUG'] = true  
  },
  computed: {
    isResult() { console.log('elect_id:',this.$store.getters['elect_id']); return this.$store.getters['elect_id'] }
  },
  methods: {
    out (confirm = false) { 
      if (!confirm) { this.$store.dispatch('user/logout'); this.$router.push({ path: '/' }) }
      else {
        if (this.$route.path == '/manage') return
        this.confirmOut()
          .then(() => {this.$store.dispatch('user/logout'); this.$router.push({ path: '/' })})
          .catch(() => {})
      }
    },
    
    async confirmOut() { // подтверждение выхода, возвращается Promise
      let opt = {confirmButtonText: 'Выйти', cancelButtonText: 'Отмена', type: 'warning'}
      await this.$confirm('Выйти из Личного кабинета ?', 'Подтвердите действие', opt)
    },

    beforeWindowUnload(e) { // обработка события "до перезагузки(закрытия)"
      if (this.isAuth()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      } //else {}
    },
  }
}
</script>

<style spaced>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:rgb(241, 224, 224);
}

.nav {
  display: grid;
  grid-template-rows: auto 1fr auto;
  place-items: center;
  padding: 10px;
}
.nav a.router-link-exact-active {
  color: #80abeb;
}
.router-link {
  margin: 5px; padding: 5px;
} 
.buttxt {
  font-size: 1.1rem;
}
/* .el-header,
.el-footer {
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 60px;
} */
/* .header {
  display: grid;
  place-items: center;
  grid-template-columns: 20% 46% 25% 1fr;  
} */

</style>
