
const getters = {
    debug: state => state.user.debug,
    isAuth: state => state.user.isAuth,
    isAdmin: state => state.user.isAdmin, // /^admin/.test( state.user.user_da.grup ),
    isAdm: state => state.user.isAdm,     // урезанный admin
    user_da: state => state.user.user_da,
    user_dae: state => {
        const props = Object.getOwnPropertyNames(state.user.user_da)
        let usda = {}
        props.forEach(prop => { usda[prop] = '' }) 
        return usda 
    },
    userFIO: state => `${state.user.user_da.name_f} ${state.user.user_da.name_i} ${state.user.user_da.name_o}`,

    kl_elector: state => state.user.kl_elector,
    elect_id: state => (state.user.curElect ? state.user.curElect.id : null),
    curElect: state => state.user.curElect,
    curResult: state => state.user.curResult,
    curElectContent: state => JSON.parse(state.user.curElect.content),
    curElectElements: state => JSON.parse(state.user.curElect.content).pages[0].elements,
    curElectQuestions: state => JSON.parse(state.user.curElect.content).pages[0].elements.filter( (el) => el.choices.length > 0 ),
    actual: state => state.user.actual,
}

export default getters